import React, { Component } from "react";
import { Navbar, Nav, NavItem } from "react-bootstrap";

class NavBar extends Component {
  testOnSelect = key => {
    alert(`selected ${key}`);
  };

  handleSelect = key => {
    switch (key) {
      case "inicio":
      case "tarjetas":
      case "webProgresiva":
      case "servicios":
      case "contacto":
        this.props.onChangeView(key);
        break;
      default:
        break;
    }
  };

  _handleSelect = (key, array) => {
    for (let index = 0; index < array.length; index++) {
      if (key === array[index]) {
        this.props.onChangeView(key);
      }
    }
  };

  render() {
    return (
      <Navbar
        className={this.props.clase}
        inverse
        collapseOnSelect
        onSelect={this.handleSelect}
        fixedTop
      >
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            <NavItem eventKey={"inicio"}>Inicio</NavItem>
            <NavItem eventKey={"tarjetas"}>Tarjetas</NavItem>
            <NavItem eventKey={"webProgresiva"}>Web Progresiva</NavItem>
            <NavItem eventKey={"servicios"}>Servicios</NavItem>
            <NavItem eventKey={"contacto"}>Contacto</NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
