import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Tab, Tabs } from "react-bootstrap";

import Navbar from "./components/navbar";
import Imagen from "./components/imagen";

import * as NavBarActions from "./actions/navbarActions";

import "./estilo.css";
import "./estiloLP.css";

class MainApp extends Component {
  onChangeView = newView => {
    this.props.changeView(newView);
    console.log(newView);
  };

  onChangeSubView = newSubView => {
    this.props.changeSubView(newSubView);
    console.log("changing sub view: " + newSubView);
  };

  render() {
    return (
      <div className="app">
        <div className="contenedor">
          <div className="gradiente-horizontal" />

          <Navbar
            title="IDDigital"
            onChangeView={this.onChangeView}
            onChangeSubView={this.onChangeSubView}
            clase="testing-navbar"
          />
          <Tabs
            activeKey={this.props.app.acive_view}
            onSelect={this.ptmOnSelect}
            id="controlled-tab-example"
          >
            <Tab eventKey={"inicio"}>
              <Imagen imgDir={`../img/logo.png`} />
              <div
                className="Rectangle-2-copy-4"
                style={{
                  backgroundColor: "#e6e7e8",
                  width: "100%"
                }}
              >
                <div className="slogan">
                  <div className="flexRow">
                    <div className="Todo">TODO</div>
                  </div>
                  <div className="flexRow">
                    <div className="lo-que-necesita-tu-negocio">
                      LO QUE NECESITA TU NEGOCIO
                    </div>
                  </div>
                  <div className="flexRow align-right">
                    <div className="para-ser-un-xito">PARA SER UN </div>
                    <div className="para-ser-un-xito text-style-1">ÉXITO</div>
                  </div>
                </div>
              </div>
              <div className="p-inicio">
                <p>
                  Siempre, apostamos por la innovación y la especialización en
                  todas aquellas disciplinas que nos ayudan a obtener los
                  mejores resultados, soluciones diseñadas para incrementar tus
                  ventas, multiplicar y dar soporte a tu cartera de clientes.
                </p>
                <p>
                  {" "}
                  Innovadores y pioneros en México en{" "}
                  <b>Desarrollo Web Progresivo</b>, la forma de crear Apps que
                  operan desde la Nube, que no necesitan descargarse, no ocupan
                  espacio, ni recursos excesivos de tu teléfono celular y funcionan en
                  cualquier dispositivo o plataforma.
                </p>
              </div>
            </Tab>

            {/* TAB SERVICIOS */}

            <Tab eventKey={"servicios"}>
              <h1 className="seccion">Servicios</h1>
              <div className="servicios-row">
                <div className="servicios-col">
                  <div className="servicios proyectoPar">
                    <h3>
                      i<div className="primeraLetra">DC</div>orporativa
                    </h3>
                    <p className="servicio">
                      Especialmente diseñada para corporativos y empresas con
                      varias sucursales.
                    </p>
                  </div>
                </div>
                <div className="servicios-col">
                  <div className="servicios proyectoImpar">
                    <h3>
                      i<div className="primeraLetra">DP</div>ersonalizada
                    </h3>
                    <p className="servicio">
                      Cree su propia Tarjeta de acuerdo a sus necesidades
                      añadiendo o eliminando cualquier servicio incluido en este
                      catálogo
                    </p>
                  </div>
                </div>
              </div>

              <div className="servicios-row">
                <div className="servicios-col">
                  <div className="servicios proyectoPar">
                    <h3>
                      i<div className="primeraLetra">DC</div>arrito
                    </h3>
                    <p className="servicio">
                      Una opción económica y sencilla para comercializar sus
                      productos y/o servicios
                    </p>
                  </div>
                </div>
                <div className="servicios-col">
                  <div className="servicios proyectoImpar">
                    <h3>
                      i<div className="primeraLetra">DS</div>tore
                    </h3>
                    <p className="servicio">
                      La tienda virtual es un sitio web que como una Aplicación Web Progresiva 
                      vende productos o servicios y por lo general, tiene un
                      carrito de compra asociado a ella, tiene distintas formas
                      de pago, incluye normalmente ficha técnica del producto,
                      imágenes y/o video, condiciones de compra y entrega,
                      existencia en inventario, etc.
                    </p>
                  </div>
                </div>
              </div>

              <div className="servicios-row">
                <div className="servicios-col">
                  <div className="servicios proyectoPar">
                    <h3>
                      i<div className="primeraLetra">DN</div>otificaciones
                      <div className="primeraLetra">P</div>ush
                    </h3>
                    <p className="servicio">
                      Este complemento facilita establecer un canal de
                      comunicación entre la empresa y el cliente, enviando
                      notificaciones push
                    </p>
                  </div>
                </div>
                <div className="servicios-col">
                  <div className="servicios proyectoImpar">
                    <h3>
                      i<div className="primeraLetra">DO</div>fertas
                    </h3>
                    <p className="servicio">
                      Este complemento muestra en un apartado especial,
                      productos o servicios que tengan alguna característica
                      especial como: precios rebajados, descuentos, planes de
                      pagos, etc.
                    </p>
                  </div>
                </div>
              </div>

              <div className="servicios-row">
                <div className="servicios-col">
                  <div className="servicios proyectoPar">
                    <h3>
                      i<div className="primeraLetra">DE</div>mail-
                      <div className="primeraLetra">M</div>arketing
                    </h3>
                    <p className="servicio">
                      Con este complemento creamos una página de aterrizaje y la
                      instalamos en la PWA para que capture los correos
                      electrónicos de los clientes y se les envíe información
                      mediante boletines de correo electrónico
                    </p>
                  </div>
                </div>
                <div className="servicios-col">
                  <div className="servicios proyectoImpar">
                    <h3>
                      i<div className="primeraLetra">DP</div>ágina
                      <div className="primeraLetra">PWA</div>
                    </h3>
                    <p className="servicio">
                      Desarrollo de una página Web mediante tecnología PWA.
                    </p>
                  </div>
                </div>
              </div>
            </Tab>

            {/* TAB TARJETAS */}

            <Tab eventKey={"tarjetas"}>
              <div
                style={{
                  margin: "auto",
                  marginTop: "20px",
                  marginBottom: "10px",
                  backgroundColor: "#e6e7e8"
                }}
              >
                <h1 className="seccion">
                  Tarjetas digitales funcionales
                  <img src="../img/logo-h.png" alt=""/>
                </h1>
              </div>

              <div className="tarjetas-row">
                <div className="tarjetas-col">
                  <h3>iDContact</h3>
                  <div className="tarjetas proyectoPar">
                    <ul className="card-specs">
                      <li>
                        <b>Tipo de tarjeta:</b> Social.
                      </li>
                      <li>
                        <b>Información del contacto:</b> Consta de tu nombre,
                        fotografía, dirección, teléfono, e-mail y tus redes
                        sociales.
                      </li>
                      <li>
                        <b>Botones fijos:</b> Cuenta con un botón para descargar
                        la tarjeta en formato VCF (Archivo de tarjeta virtual), también se puede
                        descargar como ícono en el escritorio, Botón para
                        compartir a través de código QR, e-mail, redes sociales
                        y/o Whats App.
                      </li>
                      <li>
                        <b>Uso Recomendado:</b> Personas físicas sin actividad
                        empresarial, presentación personal, tarjeta familiar, fines
                        sociales, etc.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tarjetas-col">
                  <h3>iDContact Plus</h3>
                  <div className="tarjetas proyectoImpar">
                    <ul className="card-specs">
                      <li>
                        <b>Tipo de tarjeta:</b> Profesional
                      </li>
                      <li>
                        <b>Información del contacto:</b> Consta de tu nombre,
                        fotografía, dirección, teléfono, e-mail y redes
                        sociales.
                      </li>
                      <li>
                        <b>Información del Servicio:</b> Consta de un espacio
                        para el nombre del negocio, logotipo, descripción del
                        servicio o giro comercial, cargo o profesión, e-mail del
                        negocio, puede usar su propio dominio (si lo tiene) e
                        incluir una presentación (documento PDF).
                      </li>
                      <li>
                        <b>Botones fijos:</b> Cuenta con un botón para descargar
                        la tarjeta en formato VCF (Archivo de Tarjeta Virtual), también se puede
                        descargar como ícono en el escritorio, Botó para
                        compartir a través de código QR, e-mail, redes sociales
                        y/o Whats App.
                      </li>
                      <li>
                        <b>Uso Recomendado:</b> Personas físicas con actividad
                        empresarial, profesionistas, micro y pequeñas empresas,
                        etc.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tarjetas-col">
                  <h3>iDEjecutiva</h3>
                  <div className="tarjetas proyectoPar">
                    <ul className="card-specs">
                      <li>
                        <b>Tipo de tarjeta:</b> Comercial
                      </li>
                      <li>
                        <b>Información del contacto:</b> Consta de tu nombre,
                        fotografía, dirección, teléfono, e-mail y redes
                        sociales.
                      </li>
                      <li>
                        <b>Información del Servicio:</b> Consta de un espacio
                        para el nombre del negocio, logotipo, descripción del
                        servicio o giro comercial, cargo o profesión, e-mail del
                        negocio, puede usar su propio dominio (si lo tiene) e
                        incluir una presentación (documento PDF)
                      </li>
                      <li>
                        <b>Información adicional en Índice:</b> Cuenta con un
                        índice con 5 menús, y 5 submenús: nombre e información
                        de acuerdo con el giro del negocio, objetivo del
                        proyecto, etc., se pueden incluir texto, imagen, video y
                        enlaces a otras fuentes de información. Se sugiere
                        conservar una sección de galería y una de contacto.
                      </li>
                      <li>
                        <b>Botones fijos:</b> Cuenta con un botón para descargar
                        la tarjeta en formato VCF (Archivo de Tarjeta Virtual), también se puede
                        descargar como ícono en el escritorio, Botó para
                        compartir a través de código QR, e-mail, redes sociales
                        y/o Whats App.
                      </li>
                      <li>
                        <b>Uso Recomendado:</b> Personas físicas con actividad
                        empresarial, profesionistas, micro y pequeñas empresas,
                        etc.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tarjetas-col">
                  <h3>iDEmpresarial</h3>
                  <div className="tarjetas proyectoImpar">
                    <ul className="card-specs">
                      <li>
                        <b>Tipo de tarjeta:</b> Empresarial
                      </li>
                      <li>
                        <b>Información del contacto:</b> Consta de tu nombre,
                        fotografía, dirección, teléfono, e-mail y redes
                        sociales.
                      </li>
                      <li>
                        <b>Información del Servicio:</b> Consta de un espacio
                        para el nombre del negocio, logotipo, descripción del
                        servicio o giro comercial, cargo o profesión, e-mail del
                        negocio, puede usar su propio dominio (si lo tiene) e
                        incluir una presentación (documento PDF)
                      </li>
                      <li>
                        <b>Información adicional en Índice:</b> Cuenta con un
                        índice con 5 menús, y 10 submenús: nombre e información
                        de acuerdo con el giro del negocio, objetivo del
                        proyecto, etc., se pueden incluir texto, imagen, video y
                        enlaces a otras fuentes de información. Se sugiere
                        conservar una sección de galería y una de contacto.
                      </li>
                      <li>
                        <b>Botones fijos:</b> Cuenta con un botón para descargar
                        la tarjeta en formato VCF (Archivo de Tarjeta Virtual), también se puede
                        descargar como ícono en el escritorio, Botó para
                        compartir a través de código QR, e-mail, redes sociales
                        y/o Whats App.
                      </li>
                      <li>
                        <b>Uso Recomendado:</b> Personas físicas con actividad
                        empresarial, profesionistas, micro y pequeñas empresas,
                        etc.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey={"webProgresiva"}>
              <h1 className="seccion">Aplicaciones Web Progresivas</h1>
              <div className="webAppProgresiva">
                <div>
                  Las{" "}
                  <div className="naranja-bold">
                    Aplicaciones Web Progresivas
                  </div>{" "}
                  son experiencias que combinan lo mejor de la{" "}
                  <div className="naranja-italica">Web</div> y lo mejor de las{" "}
                  <div className="naranja-italica">Apps</div>. Están disponibles
                  para los usuarios a partir de la primera visita en una pestaña
                  del navegador y no requieren instalación. A medida que el
                  usuario compila progresivamente una relación con la app con el
                  paso del tiempo, se hace más y más poderosa. Se carga
                  rápidamente, incluso con redes débiles, envía notificaciones{" "}
                  <div className="naranja-bold">PUSH</div>, tiene un ícono en la
                  pantalla principal y se carga como experiencia de pantalla
                  completa y de primer nivel.
                </div>
              </div>
              <h3 style={{ textAlign: "center", fontSize: "3em" }}>
                Características:
              </h3>
              <div className="caracteristicas">
                <p>
                  <div>Progresiva:</div> funciona para todos los usuarios, sin
                  importar la elección de navegador.
                </p>
                <p>
                  <div>Adaptable:</div> se adapta a cualquier factor de
                  formulario, sea escritorio, móvil, tableta o lo que venga en
                  el futuro.
                </p>
                <p>
                  <div>Independiente de la conectividad:</div> mejorada con
                  service workers para trabajar sin conexión o con redes de mala
                  calidad. No ocupa recursos ni espacio de tu dispositivo.
                </p>
                <p>
                  <div>Estilo app:</div> al usuario le parece una app con
                  interacciones y navegación estilo app, porque está construida
                  con modelo de shell de app.
                </p>
                <p>
                  <div>Fresca:</div> siempre actualizada gracias al proceso de
                  actualización de service worker.
                </p>
                <p>
                  <div>Segura:</div> emitida vía HTTPS para evitar intromisiones
                  y para garantizar que el contenido no se haya manipulado.
                </p>
                <p>
                  <div>Descubrible:</div> se puede identificar como "app"
                  gracias al manifiesto W3C y al alcance de registro de service
                  worker, lo que permite que los motores de búsqueda la
                  encuentren.
                </p>
                <p>
                  <div>Posibilidad de volver a interactuar:</div> facilita la
                  posibilidad de volver a interactuar a través de funciones como
                  notificaciones push.
                </p>
                <p>
                  <div>Instalable:</div> les permite a los usuarios "conservar"
                  las apps que les resultan más útiles en su pantalla principal
                  sin la molestia de una tienda de app.
                </p>
                <p>
                  <div>Vinculable:</div> se puede compartir fácilmente vía URL,
                  QR, Redes Sociales, no requiere instalación compleja.
                </p>
              </div>
            </Tab>
            <Tab eventKey={"contacto"}>
              <h1
                className="seccion"
                style={{
                  margin: "auto",
                  marginTop: "20px",
                  marginBottom: "10px"
                }}
              >
                Excelencia en MArketing Digital Avanzado
              </h1>
              <div
                className="Rectangle-2-copy-4"
                style={{
                  backgroundColor: "#e6e7e8",
                  width: "100%"
                }}
              >
                <div className="slogan">
                  <div className="flexRow">
                    <div className="Todo">TODO</div>
                  </div>
                  <div className="flexRow">
                    <div className="lo-que-necesita-tu-negocio">
                      LO QUE NECESITA TU NEGOCIO
                    </div>
                  </div>
                  <div className="flexRow align-right">
                    <div className="para-ser-un-xito">PARA SER UN </div>
                    <div className="para-ser-un-xito text-style-1">ÉXITO</div>
                  </div>
                </div>
              </div>

              <div className="contactanos">
                Escríbenos y un agente se pondrá en contacto
              </div>

              <p className="mailContacto">
                <a href="mailto:info@iddigital.com.mx">info@iddigital.com.mx</a>
              </p>
              <div className="logoH">
                <img src="../img/logo-h.png" alt=""/>
              </div>
            </Tab>
          </Tabs>
          <div className="gradiente-horizontal" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  system: state.system,
  app: state.app
});

const mapActionsToProps = dispatch => {
  return bindActionCreators(
    {
      changeView: NavBarActions.changeView,
      changeSubView: NavBarActions.changeSubView
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MainApp);
